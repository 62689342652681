/* eslint-disable */
class FullScreen {
	constructor() {
		this.fullScreen = document.getElementsByClassName("fullscreen-section")[0];
		if (this.fullScreen) {
			this.init();
		}
	}

	init() {
		window.loadJS("/wp-content/themes/arta-capital/js/full-screen.js", () => {
			window.jQueryFullScreen();
		});
	}
}

export default FullScreen;
