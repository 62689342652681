/* eslint-disable */
class Map {
	constructor() {
		window.googleapi = false;
		this.mapContainer = document.getElementsByClassName("map")[0];
		this.cookiesConsent = (document.cookie.match(
			/^(?:.*;)?\s*cookies_consent\s*=\s*([^;]+)(?:.*)?$/
		) || [null])[1];

		if (
			this.cookiesConsent &&
			this.cookiesConsent.includes("true") &&
			this.mapContainer !== undefined
		) {
			/* eslint-disable-line */
			if (window.googleapi === false) {
				window.loadJS(
					"https://maps.googleapis.com/maps/api/js?key=" +
						googleMapsKey +
						"&callback=window.map.init"
				); /* eslint-disable-line */
				window.googleapi = true;
			}
		}
	}

	init() {
		const mapContainer = document.getElementsByClassName("map__container")[0];
		const locationLatitude = mapContainer.dataset.lat;
		const locationLongitude = mapContainer.dataset.lng;
		const zoom = mapContainer.dataset.zoom;
		let height = new google.maps.LatLng(
			locationLatitude,
			locationLongitude
		); /* eslint-disable-line */
		const mapOptions = {
			zoom: parseInt(zoom),
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			center: height,
		};
		let map = new google.maps.Map(
			mapContainer,
			mapOptions
		); /* eslint-disable-line */
		let directionsDisplay =
			new google.maps.DirectionsRenderer(); /* eslint-disable-line */
		const marker = new google.maps.Marker({
			/* eslint-disable-line */ position: height,
			map: map,
			title: "Artá Capital Theme",
		});

		mapContainer.classList.remove("u-visually-hidden");
		directionsDisplay.setMap(map);

		// please note,
		// that IE11 now returns undefined again for window.chrome
		// and new Opera 30 outputs true for window.chrome
		// and new IE Edge outputs to true now for window.chrome
		// and if not iOS Chrome check
		// so use the below updated condition
		const isChromium = window.chrome;
		const winNav = window.navigator;
		const vendorName = winNav.vendor;
		const isOpera = winNav.userAgent.indexOf("OPR") > -1;
		const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
		const isIOSChrome = winNav.userAgent.match("CriOS");

		if (isIOSChrome) {
			// is Google Chrome on IOS
		} else if (
			(isChromium !== null &&
				isChromium !== undefined &&
				vendorName === "Google Inc." &&
				isOpera === false &&
				isIEedge === false) ||
			navigator.userAgent.indexOf("Safari") !== -1
		) {
			// is Google Chrome or Safari
			document.addEventListener("DOMContentLoaded", function (event) {
				document.getElementById("panel").style.display = "none";
			});
		} else {
			// not Google Chrome
		}
	}
}

export default Map;
