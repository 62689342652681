/* eslint-disable */
import Pagination from "../components/pagination.js";

class Filters {
	constructor() {
		this.filtersButtons = document.getElementsByClassName("js-filters");

		if (this.filtersButtons[0]) {
			this.init();
		}
	}

	init() {
		const filterPosts = this.filterPosts;
		const removeElementsByClass = this.removeElementsByClass;
		Array.prototype.forEach.call(this.filtersButtons, function (filterButton) {
			filterButton.addEventListener("click", function (event) {
				const old_filters =
					this.parentNode.parentNode.getElementsByClassName("filter-select");
				console.log(
					"test:" +
						this.closest(".titulofiltro").getElementsByClassName(
							"tit-filter"
						)[0].innerHTML
				);
				this.closest(".titulofiltro").getElementsByClassName(
					"tit-filter"
				)[0].innerHTML = this.innerHTML;
				if (old_filters.length > 0) {
					old_filters[0].classList.remove("filter-select");
				}
				this.classList.add("filter-select");
				const filters = document.getElementsByClassName("filter-select");
				let taxonomy = [];
				let term = [];
				for (var i = 0; i < filters.length; i++) {
					taxonomy.push(filters[i].dataset.taxonomy);
					term.push(filters[i].dataset.term);
				}

				const postType = this.dataset.postType;
				const columns = this.dataset.columns;
				const titleLength = this.dataset.titleLength;
				const pagination = this.dataset.pagination;
				const viewAll = this.dataset.viewAll;
				const maxPosts = this.dataset.maxPosts;

				event.preventDefault();
				filterPosts(
					postType,
					taxonomy,
					term,
					titleLength,
					pagination,
					viewAll,
					maxPosts,
					columns,
					this,
					removeElementsByClass
				);
			});
		});
	}

	filterPosts(
		postType,
		taxonomy,
		term,
		titleLength,
		pagination,
		viewAll,
		maxPosts,
		columns,
		element,
		removeElementsByClass
	) {
		let taxonomy1 = taxonomy[0];
		let taxonomy2 = taxonomy[1];
		let term1 = term[0];
		let term2 = term[1];
		const request = window.ajaxReq();
		let url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
		const data =
			"action=filter_posts" +
			"&post-type=" +
			postType +
			"&taxonomy1=" +
			taxonomy1 +
			"&term1=" +
			term1 +
			"&taxonomy2=" +
			taxonomy2 +
			"&term2=" +
			term2 +
			"&title-length=" +
			titleLength +
			"&pagination=" +
			pagination +
			"&view-all=" +
			viewAll +
			"&max-posts=" +
			maxPosts +
			"&columns=" +
			columns +
			"&nonce=" +
			scripts_ajax_variables.nonce; // eslint-disable-line
		request.open("POST", url, true); // set true for async, false for sync request
		request.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		request.send(data); // or null, if no parameters are passed

		// On success append data
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				try {
					const grid = element.closest(".grid");
					const gridCards = grid.getElementsByClassName("js-grid-cards")[0];

					// Remove current data
					removeElementsByClass(gridCards, "grid__card");

					// Append data
					gridCards.innerHTML = request.responseText;

					// Reload load more button
					resetPos();
					window.pagination = new Pagination();

					// Trigger lazy loading
					window.lazyLoading.init();
				} catch (error) {
					console.log(error);
				}
			}
		};
	}

	removeElementsByClass(element, className) {
		var elements = element.getElementsByClassName(className);

		while (elements.length > 0) {
			elements[0].parentNode.removeChild(elements[0]);
		}
	}
}

export default Filters;
