/* eslint-disable */
class ContactForm {
	constructor() {
		this.init();
	}

	init() {
		this.firstForm = document.getElementById("contact-form");
		this.firstFormPrivacyPolicy = document.getElementById("input-form");
		this.firstFormSendButton =
			document.getElementsByClassName("contact-button")[0];
		this.closeNotificationButton =
			document.getElementById("close-notification");
		if (this.firstForm) {
			this.newForm();
		}
	}

	newForm() {
		// Load Re-captcha V3 or throw an error
		if (googleRecaptchaWebKey) {
			// eslint-disable-line
			var script = document.createElement("script");
			script.setAttribute("type", "text/javascript");
			script.setAttribute(
				"src",
				"https://www.google.com/recaptcha/api.js?render=" +
					googleRecaptchaWebKey
			); // eslint-disable-line
			document.getElementsByTagName("head")[0].appendChild(script);

			// Make sure the privacy policy value is unchecked
			this.firstFormPrivacyPolicy.checked = false;
			this.firstFormPrivacyPolicy.value = "unchecked";

			// Listen for privacy policy checkbox changes
			this.firstFormPrivacyPolicy.addEventListener(
				"change",
				this.privacyPolicyCheckboxToogle
			);

			// Prevent form submit
			this.firstForm.addEventListener("submit", this.preventFormSubmit);

			// Form validations and submit
			this.firstFormSendButton.addEventListener(
				"click",
				this.submitValidateForm
			);

			// Close notificacion banner
			this.closeNotificationButton.addEventListener(
				"click",
				this.closeNotificationBanner
			);

			// Enable submit button
			this.firstFormSendButton.disabled = false;
		} else {
			console.error("Set up the Google ReCaptcha V3 keys");
		}
	}

	privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
		if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
			privacyPolicyCheckbox.srcElement.value = "checked";
		} else {
			privacyPolicyCheckbox.srcElement.value = "unchecked";
		}
	}

	preventFormSubmit(evt) {
		evt.preventDefault();
	}

	submitValidateForm(evt) {
		// Get the form parameters
		var action = "contact_form";
		var firstName = document.getElementsByName("first_name")[0];
		var lastName = document.getElementsByName("last_name")[0];
		var email = document.getElementsByName("email")[0];
		var message = document.getElementsByName("message")[0];
		var privacyPolicy = document.getElementsByName("privacy_policy")[0];
		var successMessage = document.getElementsByName("success_message")[0];
		var errorMessage = document.getElementsByName("error_message")[0];
		var notificationsBanner = document.getElementById("notification-banner");
		var notificationMessage =
			document.getElementsByClassName("notification-text")[0];

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Recaptcha validation
		grecaptcha.ready(function () {
			// eslint-disable-line
			grecaptcha
				.execute(googleRecaptchaWebKey, { action: action })
				.then(function (token) {
					// eslint-disable-line

					// Ajax form submit
					var xmlhttp = window.ajaxReq();
					var url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
					var currentLanguage = encodeURI(
						scripts_ajax_variables.current_language
					); // eslint-disable-line
					var defaultLanguage = encodeURI(
						scripts_ajax_variables.default_language
					); // eslint-disable-line
					var params =
						"action=" +
						action +
						"&first_name=" +
						firstName.value +
						"&last_name=" +
						lastName.value +
						"&email=" +
						email.value +
						"&message=" +
						message.value +
						"&privacy_policy=" +
						privacyPolicy.value +
						"&token=" +
						token +
						"&current_language=" +
						currentLanguage +
						"&default_language=" +
						defaultLanguage;
					xmlhttp.open("POST", url, true); // set true for async, false for sync request
					xmlhttp.setRequestHeader(
						"Content-type",
						"application/x-www-form-urlencoded"
					);
					xmlhttp.send(params); // or null, if no parameters are passed

					// Validation
					xmlhttp.onreadystatechange = function () {
						if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
							try {
								var data = JSON.parse(xmlhttp.responseText);

								// Hide previous errors
								firstName.classList.remove("show-error");
								lastName.classList.remove("show-error");
								email.classList.remove("show-error");
								message.classList.remove("show-error");
								privacyPolicy.classList.remove("show-error");

								if (data.validation === false) {
									// Show errors
									if (data.first_name) {
										firstName.classList.add("show-error");
									}
									if (data.last_name) {
										lastName.classList.add("show-error");
									}
									if (data.email) {
										email.classList.add("show-error");
									}
									if (data.message) {
										message.classList.add("show-error");
									}
									if (data.privacy_policy) {
										privacyPolicy.classList.add("show-error");
									}
								} else if (data.success === false) {
									// Error notification message
									notificationMessage.innerHTML = errorMessage.value;

									// Show notification
									notificationsBanner.classList.add("active");
								} else {
									// Success notification message
									notificationMessage.innerHTML = successMessage.value;

									// Clear the form
									evt.srcElement.form.reset();

									// Show notification
									notificationsBanner.classList.add("active");
								}

								// Enable and stop the submit button animation
								evt.srcElement.disabled = false;
								evt.srcElement.classList.remove("validating");
							} catch (error) {
								// Show notification
								notificationsBanner.classList.add("active");
							}
						}
					};
				});
		});
	}

	closeNotificationBanner(evt) {
		evt.preventDefault();
		evt.srcElement.parentNode.classList.remove("active");
	}
}

export default ContactForm;
