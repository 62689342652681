/* eslint-disable */
class Facts {
	constructor() {
		this.facts = document.getElementsByClassName("facts")[0];
		if (this.facts) {
			this.init();
		}
	}

	init() {
		function resetNumbers() {
			var factsSections = document.getElementsByClassName("facts");
			Array.prototype.forEach.call(factsSections, function (factsSection) {
				var factsListItems =
					factsSection.getElementsByClassName("js-facts-list-item");
				for (var i = 0; i < factsListItems.length; i++) {
					var factsListItemNumber = factsListItems
						.item(i)
						.getElementsByClassName("js-facts-number")[0];
					factsListItemNumber.getElementsByTagName("span")[0].innerHTML = 0;
				}
			});
		}

		function startNumbersAnimation() {
			var factsSections = document.getElementsByClassName("facts");

			function getRandomArbitrary(min, max) {
				return Math.random() * (max - min) + min;
			}

			function numbersAnimation(elem, endNumber) {
				var number = 0;
				var randomNumber = getRandomArbitrary(1, 10);
				var acceleration = endNumber / 20 + randomNumber;

				if (acceleration < 1) {
					acceleration = 1;
				}

				function frame() {
					if (number + acceleration > endNumber) {
						number = endNumber;
					} else {
						number = number + acceleration;
					}
					elem.innerHTML = Math.floor(number); // show frame
					if (number >= endNumber) {
						clearInterval(id);
					} // check finish condition
				}
				var id = setInterval(frame, 70); // draw every 1ms
			}

			Array.prototype.forEach.call(factsSections, function (factsSection) {
				if (factsSection.classList.contains("animated")) {
					var factsListItems =
						factsSection.getElementsByClassName("js-facts-list-item");
					for (var i = 0; i < factsListItems.length; i++) {
						var factsListItemNumber = factsListItems
							.item(i)
							.getElementsByClassName("js-facts-number")[0];
						var dataCount = factsListItemNumber.dataset.count;
						var dataAnimation = factsListItemNumber.dataset.animation;
						if (dataAnimation === "false") {
							numbersAnimation(
								factsListItemNumber.getElementsByTagName("span")[0],
								dataCount
							);
							factsListItemNumber.dataset.animation = "true";
						}
					}
				}
			});
		}

		resetNumbers();
		window.addEventListener("scroll", startNumbersAnimation);
	}
}

export default Facts;
