/* eslint-disable */
class Numbers {
	constructor() {
		this.numbers = document.getElementsByClassName("numbers")[0];
		if (this.numbers) {
			this.init();
		}
	}

	init() {
		window.loadJS("/wp-content/themes/arta-capital/js/numbers.js", () => {
			window.jQueryNumbers();
		});
	}
}

export default Numbers;
