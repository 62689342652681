/* eslint-disable */
class LottieAnimations {
	constructor() {
		this.animatedElementsContainers =
			document.getElementsByClassName("js-lottie");
		if (this.animatedElementsContainers) {
			this.init();
		}
	}

	init() {
		const loadAnimations = this.loadAnimations;
		if (window.lottie === undefined) {
			window.loadJS(
				"/wp-content/themes/arta-capital/js/vendor/lottie.js",
				() => {
					Array.prototype.forEach.call(
						this.animatedElementsContainers,
						function (animatedElementContainer) {
							loadAnimations(animatedElementContainer);
						}
					);
				}
			);
		} else {
			Array.prototype.forEach.call(
				this.animatedElementsContainers,
				function (animatedElementContainer) {
					loadAnimations(animatedElementContainer);
				}
			);
		}
	}

	loadAnimations(animatedElementContainer) {
		const animationPath = animatedElementContainer.getAttribute(
			"data-animation-path"
		);
		const loop =
			animatedElementContainer.getAttribute("data-animation-loop") === "true";
		const menu = animatedElementContainer.getAttribute("data-menu") === "true";
		const fullsection =
			animatedElementContainer.getAttribute("data-fullsection") === "true";
		if (animationPath !== undefined && animationPath !== null) {
			if (menu) {
				globalThis.animenu = window.lottie.loadAnimation({
					container: animatedElementContainer, // the dom element that will contain the animation
					renderer: "svg",
					loop: loop,
					autoplay: true,
					path: animationPath, // the path to the animation json
					rendererSettings: {
						scaleMode: "noScale",
					},
				});
				animenu.goToAndStop(0);
			} else if (fullsection) {
				globalThis.animfullsection = window.lottie.loadAnimation({
					container: animatedElementContainer, // the dom element that will contain the animation
					renderer: "svg",
					loop: loop,
					autoplay: true,
					path: animationPath, // the path to the animation json
					rendererSettings: {
						scaleMode: "noScale",
					},
				});
				animfullsection.goToAndStop(0);
			} else {
				globalThis.anim = window.lottie.loadAnimation({
					container: animatedElementContainer, // the dom element that will contain the animation
					renderer: "svg",
					loop: loop,
					autoplay: true,
					path: animationPath, // the path to the animation json
					rendererSettings: {
						scaleMode: "noScale",
					},
				});
				anim.goToAndStop(0);
			}
		}
	}
}

export default LottieAnimations;
