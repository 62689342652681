/* eslint-disable */
import LottieAnimations from "./lib/lottie";
import AnimationScenes from "./template-parts/common/animationScenes";
import LazyLoading from "./lib/lazy-loading";
import Cta from "./template-parts/components/cta";
import Filters from "./template-parts/components/filters";
import Pagination from "./template-parts/components/pagination";
import ContactForm from "./template-parts/sections/contact-form";
import Facts from "./template-parts/sections/facts";
import FullScreen from "./template-parts/sections/full-screen-section";
import Map from "./template-parts/sections/map";
import Numbers from "./template-parts/sections/numbers";
import Slider from "./template-parts/sections/slider";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		window.lottieAnimations = new LottieAnimations();
		window.animationScenes = new AnimationScenes();
		window.lazyLoading = new LazyLoading();
		window.cta = new Cta();
		window.filters = new Filters();
		window.pagination = new Pagination();
		window.contactForm = new ContactForm();
		window.facts = new Facts();
		window.fullScreen = new FullScreen();
		window.map = new Map();
		window.numbers = new Numbers();
		window.slider = new Slider();
	}
}
