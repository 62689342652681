/* eslint-disable */
import "./scss/style.scss";
import Header from "./js/template-parts/common/header.js";
import ModulesLoader from "./js/modules-loader.js";
import Barba from "./js/lib/barba.js";
import Cookies from "./js/template-parts/popups/cookies.js";
import ScrollMagic from "scrollmagic";
import DisableScroll from "./js/lib/disable-scroll";

// Ajax requests
window.ajaxReq = function ajaxReq() {
	if (window.XMLHttpRequest) {
		return new XMLHttpRequest(); // eslint-disable-line
	} else if (window.ActiveXObject) {
		return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line
	} else {
		console.log("Browser does not support XMLHTTP.");
		return false;
	}
};

// Load objects
document.addEventListener("DOMContentLoaded", function (event) {
	// Load JS scripts
	window.loadJS = function loadJS(file, callback) {
		// DOM: Create the script element
		var jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Load CSS styles
	window.loadCSS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("link");
		// set the rel attribute
		jsElm.rel = "stylesheet";
		// set the media attribute
		jsElm.media = "all";
		// make the script element load file
		jsElm.href = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Header
	window.header = new Header();

	// BarbaJS
	window.barba = new Barba();
});

window.addEventListener("load", function (event) {
	window.slideDown = function (el) {
		var elem = document.getElementById(el);
		elem.style.height = "100vh";
	};

	window.slideUp = function (el) {
		var elem = document.getElementById(el);
		elem.style.height = "0px";
	};

	// Disable scroll
	window.disableScroll = new DisableScroll();

	// Modules loader
	window.modulesLoader = new ModulesLoader();
	window.modulesLoader.loadModules();

	// Cookies banner
	window.cookies = new Cookies();

	// Animations
	window.animationScenes(new ScrollMagic.Controller());
});
