/* eslint-disable */
import cookie from "mf-js/modules/cookies/cookie";

const consentManager = (options = {}) => {
	const { cookieName } = Object.assign(
		{ cookieName: "cookies_consent" },
		options
	);

	const STATUS_WAIT = "wait";
	const STATUS_TRUE = "true";
	const STATUS_FALSE = "false";

	const cookies = (cookie(cookieName) || "")
		.split("!")
		.filter((item) => item)
		.map((item) => item.split("="))
		.map((item) => ({ key: item[0], status: item[1] }));

	const servicesStatus = {};
	cookies.forEach(({ key, status }) => {
		switch (status) {
			case STATUS_TRUE:
			case STATUS_FALSE:
				servicesStatus[key] = status;
				break;

			default:
				servicesStatus[key] = STATUS_WAIT;
		}
	});

	const writeCookie = () =>
		cookie(
			cookieName,
			servicesList.reduce(
				(curry, key) => curry + "!" + key + "=" + servicesStatus[key],
				""
			),
			{ expires: 365, path: "/" }
		);

	const deleteCookie = () => {
		document.cookie = cookieName + "=;expires=Thu; 01 Jan 1970";

		return manager;
	};

	/**************************************************
	 * SERVICES
	 **************************************************/

	const services = {};
	const servicesList = [];
	const servicesWaiting = [];

	const register = (service, key = false) => {
		key = key || service.key;

		const status = (servicesStatus[key] = servicesStatus[key] || STATUS_WAIT);

		servicesList.push(key);
		services[key] = services[key] ? [...services[key], service] : [service];

		service.init(manager);

		if (status === STATUS_FALSE) {
			service.refuse();
		} else if (status === STATUS_TRUE) {
			service.accept(manager);
		} else {
			servicesWaiting.push(service);
		}

		return manager;
	};

	const accept = (key) => {
		if (servicesStatus[key] !== STATUS_TRUE) {
			servicesStatus[key] = STATUS_TRUE;
			services[key].forEach((service) => service.accept(manager));
			writeCookie();
		}
		window.disableScroll.enableScroll(); // Unlock scroll
	};

	const deny = (key) => {
		servicesStatus[key] = STATUS_FALSE;
		writeCookie();

		const domain = window.location.hostname.split(".");
		while (domain.length > 1) {
			const d = domain.join(".");

			services[key].forEach((service) =>
				service.cookies.forEach((cookieName) => {
					cookie(cookieName, null, { path: "/", expires: -1, domain: "." + d });
					cookie(cookieName, null, { path: "/", expires: -1, domain: d });
				})
			);

			domain.shift();
		}

		window.disableScroll.enableScroll(); // Unlock scroll
	};

	const acceptAll = () => servicesList.forEach((key) => accept(key));
	const denyAll = () => servicesList.forEach((key) => deny(key));

	const save = (key) => {
		if (servicesStatus[key] === STATUS_TRUE) {
			accept(key);
		} else {
			deny(key);
		}
	};

	/**************************************************
	 * UI
	 **************************************************/

	let consentUI = false;

	const setUI = (u) => {
		consentUI = u;

		consentUI.setManager(manager);

		return manager;
	};

	/**************************************************
	 * LAUNCH
	 **************************************************/

	const launch = () => {
		writeCookie();

		if (consentUI && servicesWaiting.length > 0) {
			window.disableScroll.disableScroll(); // Block scroll
			consentUI.requireConsent();
		} else if (consentUI) {
			consentUI.modifyConsent();
		}

		return manager;
	};

	const manager = {
		setUI,
		deleteCookie,
		register,
		launch,
		accept,
		deny,
		acceptAll,
		denyAll,
		save,
		services: () =>
			servicesList.map((key) => ({
				key,
				service: services[key],
				status: servicesStatus[key],
			})),
	};

	return manager;
};

export default consentManager;
