/* eslint-disable */
class Header {
	constructor() {
		this.init();
	}

	init() {
		// Load Jquery animations
		window.loadJS(
			"/wp-content/themes/arta-capital/js/vendor/jquery-3.6.0.js",
			() => {
				window.loadJS("/wp-content/themes/arta-capital/js/header.js", () => {});
			}
		);
	}
}

export default Header;
